<template>
    <div class="row flex justify-content-center">
        <button class="btn btn-warning"> <router-link class="nav-link text-white" to="/">Back to Home</router-link>
        </button>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.btn-warning {
    background: #0c4369 !important;
    border-color: #0c4369;
}
</style>