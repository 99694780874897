<template>
  <div>
    <div class="row flex" style="height:100vh">
      <!-- Left side: Login form -->
      <div class="col-md-6  p-5" v-show="screen === 1">
        <backtohomebtn />
        <h3 class="text-xl text-center text-oonpayprimary mt-8 mb-8">
          Contact Person Information
        </h3>


        <div class="form-group form-row">
          <div class="col">
            <label for="first name"> First Name</label>
            <input required type="text" v-model="contactfirstname" class="border-2 border-gray-300 rounded w-full p-3"
              placeholder="First name" />
          </div>
          <div class="col">
            <label for="Last Name"> Last Name</label>
            <input type="text" v-model="contactlastname" class="border-2 border-gray-300 rounded w-full p-3"
              placeholder="Last name" />
          </div>
        </div>
        <div class="form-group">
          <label for="Email"> Email</label>
          <input type="text" v-model="contactemailaddress" class=" border-2 border-gray-300 rounded w-full p-3"
            placeholder="Email" />
        </div>

        <div class="form-group form-row">
          <div class="col-md-4">
            <label for="Bussiness number">Select country code</label>
            <select v-model="bus_selected_code" name="business address"
              class="border-2 border-gray-300 rounded w-full p-3">

              <option v-for="cty in all_countries" :key="cty.id" :value="cty.callCode">{{ cty.fullName
                }}</option>
            </select>
          </div>
          <div class="col-md-8">
            <label for="Bussiness number">Mobile Number</label>
            <input type="number" class="border-2 border-gray-300 rounded w-full p-3" v-model="contactPhoneNumber"
              placeholder="Mobile Number" />
          </div>
        </div>

        <div v-if="requiredData.item2" class="form-group">
          <label for="Select Country">How did you hear about us?</label>
          <select v-model="campaignchannelid" name="campaignchannel"
            class="border-2 border-gray-300 rounded w-full p-3">
            <option v-for="channel in requiredData.item2" :key="channel.index" :value="channel.id">{{
              channel.fullName }}</option>
          </select>
        </div>

        <div class="">
          <button @click="GoTONext" class="
                    border border-teal-dark
                    bg-oonpayprimary
                    rounded
                    w-full
                    px-4
                    py-3
                    text-white
                    font-semibold
                  ">


            Next
          </button>
        </div>


      </div>

      <div class="col-md-6  p-5" v-show="screen === 2">
        <backtohomebtn />
        <h4>Business Information</h4>
        <div class="form-group">
          <label for="Business Name">Business Name</label>
          <input type="text" v-model="businessname" class="border-2 border-gray-300 rounded w-full p-3"
            placeholder="Enter Business Name" />
        </div>

        <div v-if="requiredData.item3" class="form-group">
          <label for="Select Country">Business Type</label>
          <select v-model="businesstypeid" name="country" class="border-2 border-gray-300 rounded w-full p-3">
            <option v-for="bus in requiredData.item3" :key="bus.id" :value="bus.id">{{ bus.fullName }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="Busness Email">Business Email</label>
          <input v-model="businessemailaddress" type="text" class="border-2 border-gray-300 rounded w-full p-3"
            placeholder="Email" />
        </div>



        <div class="form-group form-row">
          <div class="col">
            <label for="Select Country"> Business number</label>
            <input type="button" data-toggle="modal" data-target="#clientContact"
              class="border-2 border-gray-300 rounded w-full p-3 disabled-phone text-left" :value="busphone"
              placeholder="Mobile Number" />
          </div>

          <!-- Modal -->
          <div class="modal fade" id="clientContact" tabindex="-1" role="dialog" aria-labelledby="clientContactLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="clientContactLabel">
                    Verify Contact Number
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div v-if="sent_code == false" class="form-group form-row">
                    <div class="col-md-4">
                      <label for="select country code">Select country code</label>
                      <select v-model="selected_code" name="business address"
                        class="border-2 border-gray-300 rounded w-full p-3">
                        <option v-for="code in all_countries" :key="code.index" :value="code.callCode">
                          {{ code.callCode }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-8">
                      <label for="select country code">Enter phone number</label>
                      <input v-model="businessphonenumber" type="text"
                        class="border-2 border-gray-300 rounded w-full p-3" placeholder="Contact Phone Number" />
                    </div>
                  </div>

                  <div v-if="sent_code == true" class="form-group form-row">
                    <div class="col-md-12">
                      <input v-model="OTP" type="text" class="border-2 border-gray-300 rounded w-full p-3"
                        placeholder="Verification code" />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button v-if="sent_code == true" @click="goBack" type="button" class="btn btn-primary">
                    go back
                  </button>
                  <button @click="processVerification" type="button" class="btn btn-success">
                    <b-spinner v-if="loading" type="grow"></b-spinner>
                    <span v-else>{{
                      sent_code == false ? "Send Code" : "Verify"
                      }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="countries">Select your country</label>
          <select class="border-2 border-gray-300 rounded w-full p-3" @change="updateCountry" v-model="countryObject"
            name="countries">
            <option v-for="cty in all_countries" :key="cty.id" :value="cty">{{
              cty.fullName
              }}</option>
          </select>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="">
              <button @click="GoTOPrev" class="
                    border border-teal-dark
                    bg-oonpayprimary
                    rounded
                    w-full
                    px-4
                    py-3
                    text-white
                    font-semibold
                  ">

                Previous
              </button>
            </div>
          </div>
          <div class="col-md-6">
            <div class="">
              <button @click="GoTONext" class="
                    border border-teal-dark
                    bg-oonpayprimary
                    rounded
                    w-full
                    px-4
                    py-3
                    text-white
                    font-semibold
                  ">


                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6  p-5" v-show="screen === 3">
        <backtohomebtn />
        <h4 class="mb-3">Business Information</h4>
        <div class="form-group">
          <label for="Busness Email">Search Busines Location</label>
          <input ref="autocomplete" type="text" :id="id" :options="options"
            class="border-2 border-gray-300 rounded w-full p-3" placeholder="Search Business Location" />
        </div>

        <div class="form-group form-row">
          <div class="col">
            <label for="search text-">City</label>
            <input type="text" disabled placeholder="Cape Coast" v-model="addressData.city"
              class="border-2 border-gray-300 rounded w-full p-3 disabled-input" />
          </div>
          <div class="col">
            <label for="search text-">Street Address</label>
            <input type="text" placeholder="chaple street, abura" class="border-2 border-gray-300 rounded w-full p-3"
              v-model="address" />
          </div>
        </div>

        <div class="form-group">
          <label for="Busness Email">Business Website</label>
          <input type="text" v-model="businesswebsite" class="border-2 border-gray-300 rounded w-full p-3"
            placeholder="Business website" />
        </div>

        <div class="form-group">
          <label for="Busness Email">Business Registration Number</label>
          <input type="text" v-model="businessregistrationnumber" class="border-2 border-gray-300 rounded w-full p-3"
            placeholder="Business Registration Number" />
        </div>

        <div class="form-group">
          <label for="Busness Email">Number of Employees</label>
          <input type="text" class="border-2 border-gray-300 rounded w-full p-3" v-model="businessnumberofemployees"
            placeholder="Enter Number of Employees" />
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="">
              <button @click="GoTOPrev" class="
                    border border-teal-dark
                    bg-oonpayprimary
                    rounded
                    w-full
                    px-4
                    py-3
                    text-white
                    font-semibold
                  ">
                <b-spinner v-if="loading" small></b-spinner>

                Previous
              </button>
            </div>
          </div>
          <div class="col-md-6">
            <div class="">
              <button @click.prevent="postData" :disabled="isDisabled" class="
                    border border-teal-dark
                    btn btn-warning
                    bg-oonpayprimary
                    rounded
                    w-full
                    px-4
                    py-3
                    text-white
                    font-semibold
                  ">
                <b-spinner v-if="loading" small></b-spinner>

                Submit
              </button>
            </div>
          </div>
        </div>
      </div>



      <!-- Right side: Image -->
      <div class="d-none d-lg-flex  col-md-6 rightside">

      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import TheAppBar from "../../components/NavBar/TheAppBar.vue";
import Footer from "../../components/footer.vue";
import Modal from "../../components/Global/Modal.vue";
import $ from "jquery";
import Backtohomebtn from '../../components/mini/backtohomebtn.vue';

/* eslint-disable */

const ADDRESS_COMPONENTS = {
  subpremise: "short_name",
  street_number: "short_name",
  route: "long_name",
  sublocality_level_1: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  administrative_area_level_2: "long_name",
  country: "long_name",
  postal_code: "short_name"
};

export default {
  components: { TheAppBar, Footer, Modal, FadeLoader, Backtohomebtn },
  data() {
    return {
      screen :1,
      loading: false,
      isLoading: false,
      contactfirstname: "",
      contactlastname: "",
      contactemailaddress: "",
      contactPhoneNumber: "",
      campaignchannelid: "",
      businessname: "",
      businesstypeid: "",
      businessemailaddress: "",
      businessphonenumber: "",
      busphone: "",
      busphonetemp: "",
      businesslocation: {
        city: "",
        streetaddress: "",
        Latitude: "",
        Longitude: ""
      },
      id: "this-component",
      address: "",
      country: "gh",
      autocomplete: null,
      autocompleteText: "",
      geolocation: {
        geocoder: null,
        loc: null,
        position: null
      },
      addressData: {
        streetNumber: 0,
        street: "",
        suburb: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
        latitude: "",
        longitude: ""
      },
      bus_selected_code: "",
      countryObject: {},
      businesswebsite: "",
      businessregistrationnumber: "",
      businessnumberofemployees: "",
      requiredData: {},
      selected_code: "",
      sent_code: false,
      OTP: "",
      modalActive: false,
      errorMessage: [],
      color: "#0c4369",
      address: "",
      options: {

        componentRestrictions: {
          country: "GH"
        },
      }
    };
  },

  watch: {
    autocompleteText: function (newVal, oldVal) {
      this.$emit("inputChange", { newVal, oldVal }, this.id);
    },
    country: function (newVal, oldVal) {
      this.autocomplete.setComponentRestrictions({
        country: this.country === null ? oldVal : newVal
      });
    }
  },

  mounted() {
    this.getRequiredData();

    const options = this.options;

    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(this.id),
      options
    );

    this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    this.getCountry();
  },

  methods: {
    GoTONext(){
      this.screen++
    },
    GoTOPrev() {
      if (this.screen > 1) {
        this.screen--;
      }
    },
    goBack() {
      this.sent_code = false;
      this.businessphonenumber = "";
    },
    onPlaceChanged() {
      let place = this.autocomplete.getPlace();

      if (!place.geometry) {
        this.$emit("no-results-found", place, this.id);
        return;
      }
      if (place.address_components !== undefined) {
        this.processFormattedResults(this.formatResult(place));
      }
    },

    formatResult(place) {
      let returnData = {};

      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];

        if (ADDRESS_COMPONENTS[addressType]) {
          let val =
            place.address_components[i][ADDRESS_COMPONENTS[addressType]];
          returnData[addressType] = val;
        }
      }

      returnData["latitude"] = place.geometry.location.lat();
      returnData["longitude"] = place.geometry.location.lng();

      this.$store.dispatch(
        "healthhub/Setlat",
        (returnData["latitude"] = place.geometry.location.lat())
      );
      this.$store.dispatch(
        "healthhub/Setlong",
        (returnData["longitude"] = place.geometry.location.lng())
      );

      return returnData;
    },

    updateCountry() {
      this.country = this.countryObject.isO2Code.toLowerCase().toLowerCase();
      this.$store.dispatch("healthhub/CountryId", this.countryObject.id);
    },

    processFormattedResults(formattedRes) {
      this.addressData.streetNumber = formattedRes.street_number;
      this.address = formattedRes.address;
      this.addressData.latitude = formattedRes.latitude;
      this.addressData.longitude = formattedRes.longitude;
      this.addressData.street = formattedRes.route;
      this.addressData.suburb = formattedRes.sublocality_level_1;
      this.addressData.city = formattedRes.locality;
      this.addressData.province = formattedRes.administrative_area_level_1;
      this.addressData.country = formattedRes.country;
      this.addressData.postalCode = formattedRes.postal_code;

      this.$store.dispatch("healthhub/setCity", this.addressData.city);

      this.getAddressFrom(formattedRes.latitude, formattedRes.longitude);
    },

    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.getAddressFrom(
              position.coords.latitude,
              position.coords.longitude
            );
            this.$store.dispatch("healthhub/Setlat", position.coords.latitude);
            this.$store.dispatch(
              "healthhub/Setlong",
              position.coords.longitude
            );
          },
          (error) => {
            this.$toasted.error(
              "Locator is unable to find your address. Please search form the search box",
              {
                duration: 5000
              }
            );
          }
        );
      } else {
        this.$toasted.error("Your browser doesnt support geolocation API", {
          duration: 5000
        });
      }
    },

    getAddressFrom(lat, long) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyABmW5XJTsyoq8WrCQlXzZbyrHgyiJziXo`
        )
        .then((response) => {
          if (response.data.error_message) {
            this.$toasted.success(response.data.error_message, {
              duration: 5000
            });
          } else {
            this.address = response.data.results
              ? response.data.results[0].formatted_address
              : response.data.results[2].formatted_address;
            this.addressData.city = response.data.results.slice(
              -2
            )[0].address_components[0].long_name;
            this.$store.dispatch("healthhub/StreetAddress", this.address);
            this.$store.dispatch("healthhub/setCity", this.addressData.city);
          }
        });
    },

    getRequiredData() {
      this.loading = true;
      this.$store
        .dispatch("healthhub/getHandShakeRequiredData")
        .then((response) => {
          this.requiredData = response.data;
          this.loading = false;
        });
    },

    processVerification() {
      if (this.sent_code == false) {
        let newPhone = this.businessphonenumber;
        if (newPhone.charAt(0) == 0 || newPhone.charAt(0) == 1) {
          var phone = newPhone.substring(1);
        } else {
          var phone = newPhone
        }
        var main_number = `${this.selected_code}${phone}`;

        const data = {
          code: this.selected_code,
          phonenumber: main_number
        };
        // console.log(data)
        this.loading = true;
        this.$store
          .dispatch("pharmacy/VerifyCustomerPhone", data)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.sent_code = true;
              this.busphonetemp = main_number;
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$swal({
              icon: "error",
              title: "Error",
              text: err.response.data
            });
          });
      } else {
        this.loading = true;
        this.$store
          .dispatch("pharmacy/VerifyBusinessPhone", this.OTP)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.busphone = this.busphonetemp;
              this.businessphonenumber = this.busphonetemp
              localStorage.setItem("businessPhoneNumberGUID", response.data);
              this.sent_code = true;
              this.OTP = ""
              this.businessphonenumber = ""
              this.selected_code = ""
              $("#clientContact").modal("hide");
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$swal({
              icon: "error",
              title: "Error",
              text: err.response.data
            });
          });
      }
    },

    toggleModal() {
      this.modalActive = false;
    },

    getCountry: function () {
      this.$store.dispatch("dashboard/GetAllCountries");
    },

    postData() {
      let busPhone = this.contactPhoneNumber;
      if (busPhone.charAt(0) == 0 || busPhone.charAt(0) == 1) {
        var phone = busPhone.substring(1);
      }
      let contactphone = `${this.bus_selected_code}${phone}`;

      const formData = {
        contactfirstname: this.contactfirstname,
        contactlastname: this.contactlastname,
        contactemailaddress: this.contactemailaddress,
        contactPhoneNumber: contactphone,
        campaignchannelid: this.campaignchannelid,
        businessname: this.businessname,
        businesstypeid: this.businesstypeid,
        businessemailaddress: this.businessemailaddress,
        businessphonenumber: this.businessphonenumber,
        businesslocation: {
          city: this.addressData.city,
          streetaddress: this.address,
          Latitude: this.addressData.latitude,
          Longitude: this.addressData.longitude,
          countryId: this.countryObject.id
        },
        businesswebsite: this.businesswebsite,
        businessregistrationnumber: this.businessregistrationnumber,
        businessnumberofemployees: this.businessnumberofemployees
      };

      //console.log(formData)

      this.isLoading = true;
      this.$store
        .dispatch("crowdhubaccount/MerchantHandshake", formData)
        .then((response) => {
          if (response.status == 200) {
            this.isLoading = false;
            this.$router.push("/thank-you");
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.modalActive = true;
          this.errorMessage = err.response.data;
        });
    }
  },

  computed: {
    ...mapGetters("dashboard", ["all_countries"]),
    isDisabled() {
      return (
        !this.contactfirstname ||
        !this.contactlastname ||
        !this.contactemailaddress ||
        !this.campaignchannelid ||
        !this.businessname ||
        !this.businessemailaddress ||
        !this.busphone ||
        !this.businessnumberofemployees
      );
    }
  }
};
</script>

<style scoped>
.rightside {
  background-image: url('../../assets/auth/bg20.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.bg-main {
  background: #f3f3f3;
}

.disabled-phone {
  background-color: #e9ecef;
  color: #6c757d;
  border: 1px solid #ced4da;
}

.disabled-input {
  background-color: #e9ecef;
  color: #6c757d;
  border: 1px solid #ced4da;
  cursor: not-allowed;
}

.btn-warning {
  background: #0c4369 !important;
  border-color: #0c4369;
}
</style>


